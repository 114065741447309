var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"grid-list-md":"","text-xs-left":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"dark":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(`Form Fill Up`))])])])],1)],1)],1),(_vm.getFillUpOptions.length)?_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.getFillUpOptions),function(option,index){return _c('v-flex',{key:index,attrs:{"xs12":""}},[(
          option.ff_apply_activity ||
          (option.ff_college_confirm_activity &&
            option.student_confirm_status == 1)
        )?_c('v-card',[_c('div',{staticClass:"text-xs-center"},[_c('h3',{staticClass:"headline mb-0"},[_vm._v(" "+_vm._s(`Form Fill Up for ${option.exam_master.exam_name} (${option.exam_master.exam_year})`)+" ")])]),_c('div',{staticClass:"text-xs-center"},[(option.ff_apply_activity)?_c('v-chip',{attrs:{"color":"info","text-color":"white"}},[_vm._v("Start Date : "+_vm._s(_vm._f("dateFormat")(option.exam_master.activity_setup[0].start_date)))]):_vm._e(),(option.ff_apply_activity)?_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v("Last Date : "+_vm._s(_vm._f("dateFormat")(option.exam_master.activity_setup[0].end_date)))]):_vm._e(),(option.teacher_confirm_status == 1)?_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v(" Payment Status:Paid ")]):(option.student_confirm_status == 1)?_c('v-chip',{attrs:{"color":"orange","text-color":"white"}},[_vm._v(" Payment Status:Unpaid ")]):_vm._e()],1),_c('div',{staticClass:"text-xs-right"},[(
              option.ff_apply_activity &&
              option.exam_master.activity_setup[0].notice_url
            )?_c('v-btn',{attrs:{"dark":"","color":"info","href":option.exam_master.activity_setup[0].notice_url,"target":"_blank"}},[_vm._v(" Notice ")]):_c('v-btn',{attrs:{"dark":"","color":"red"}},[_vm._v("Notice Not Found")]),(
              option.ff_apply_activity && option.student_confirm_status == 0
            )?_c('v-btn',{attrs:{"dark":"","color":"success"},on:{"click":function($event){return _vm.goTo(`/student/ff/student_form_fill_up/${option.id}`)}}},[_vm._v("Apply Now")]):(option.student_confirm_status == 1)?_c('v-btn',{attrs:{"dark":"","color":"success"},on:{"click":function($event){return _vm.goTo(`/student/ff/form_fill_up_details/${option.id}`)}}},[_vm._v("Form Fillup Details ")]):_vm._e(),(option.student_confirm_status == 1)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue-grey","target":"_blank"},on:{"click":function($event){return _vm.getformFillupDetelsbyID(option.id)}}},[_vm._v(" Application Form "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("cloud_download")])],1):_vm._e()],1)]):(
          option.exam_master.activity_setup.length &&
          option.student_confirm_status == 1
        )?_c('v-card',[_c('div',{staticClass:"text-xs-center"},[_c('h3',{staticClass:"headline mb-0"},[_vm._v(" "+_vm._s(`Form Fill Up for ${option.exam_master.exam_name} (${option.exam_master.exam_year})`)+" ")])]),_c('div',{staticClass:"text-xs-center"},[_c('v-chip',{attrs:{"color":"info","text-color":"white"}},[_vm._v("Start Date : "+_vm._s(_vm._f("dateFormat")(option.exam_master.activity_setup[0].start_date)))]),_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v("Last Date : "+_vm._s(_vm._f("dateFormat")(option.exam_master.activity_setup[0].end_date)))]),(option.teacher_confirm_status == 1)?_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v(" Payment Status:Paid ")]):(option.student_confirm_status == 1)?_c('v-chip',{attrs:{"color":"orange","text-color":"white"}},[_vm._v(" Payment Status:Unpaid ")]):_vm._e()],1),_c('div',{staticClass:"text-xs-right"},[(option.student_confirm_status == 1)?_c('v-btn',{attrs:{"dark":"","color":"success"},on:{"click":function($event){return _vm.goTo(`/student/ff/form_fill_up_details/${option.id}`)}}},[_vm._v("Form Fillup Details ")]):_vm._e(),(option.student_confirm_status == 1)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue-grey","target":"_blank"},on:{"click":function($event){return _vm.getformFillupDetelsbyID(option.id)}}},[_vm._v(" Application Form "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("cloud_download")])],1):_vm._e()],1)]):_vm._e()],1)}),1):_vm._e(),( !_vm.getLoading && _vm.getFillUpOptions.length && !_vm.getFillUpOptions.find( (element) => element.ff_apply_activity ||
          (element.ff_college_confirm_activity &&
            element.student_confirm_status == 1) ) )?_c('v-layout',{staticClass:"mt-auto",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(`No data found.`))])])])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }